import dayjs from "dayjs"

export function getDateRange(dates: string[]) {
  const result = dates.map((date) => dayjs(date)).sort((date) => date.unix())
  const min = result[0]
  const max = result[result.length - 1]

  const minFormat = min.format("DD.MM.YY")
  const maxFormat = max.format("DD.MM.YY")

  if (minFormat === maxFormat) {
    return minFormat
  } else {
    return `${minFormat} — ${maxFormat}`
  }
}

export function simpleDate(init: string | Date) {
  if (!init) return ""
  const date = dayjs(init)
  if (!date.isValid()) return ""
  const day = date.date()
  let suffix = "th"
  if (day == 1 || day == 21 || day == 31) suffix = "st"
  if (day == 2 || day == 22) suffix = "nd"
  if (day == 3 || day == 23) suffix = "rd"
  return date.format("D") + suffix + " " + date.format("MMMM YYYY")
}

export function getDateGroupHeading(init: string | Date) {
  const date = dayjs(init)
  if (!date.isValid()) return ""
  const endOfWeek = dayjs().endOf("week")
  const endOfMonth = dayjs().endOf("month")
  if (date.isBefore(endOfWeek)) return "This week"
  if (date.isBefore(endOfMonth)) return "Later this month"
  return "Later"
}

export function formatNiceDate(init: string | Date) {
  const date = dayjs(init)
  if (!date.isValid()) return ""
  return date.format("dddd, MMMM D, YYYY")
}

export function formatNiceDateRange(d1: string | Date, d2?: string | Date | null) {
  if (!d2) return formatNiceDate(d1)
  const date1 = dayjs(d1)
  const date2 = dayjs(d2)

  if (date1.format("YYYYMMDD") === date2.format("YYYYMMDD")) {
    return formatNiceDate(d1)
  } else if (date1.format("YYYYMM") === date2.format("YYYYMM")) {
    return `${date1.format("D")}-${date2.format("D")} ${date1.format("MMMM YYYY")}`
  } else {
    return `${date1.format("D MMMM YYYY")}-${date2.format("D MMMM YYYY")}`
  }
}

export function formatNiceTime(init: string | Date) {
  const date = dayjs(init)
  if (!date.isValid()) return ""
  return date.format("h:mma")
}

export function formatNiceShortDate(init: string | Date) {
  const date = dayjs(init)
  if (!date.isValid()) return ""
  return date.format("D MMMM YYYY")
}

export function formatDateTime(init: string | Date) {
  const date = dayjs(init)
  if (!date.isValid()) return ""
  return date.format("DD MMM YYYY, h:mma")
}
